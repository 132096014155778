<template>
	<template v-if="languagesStore.isLoaded">
		<layout-header/>
		<main :class="currentSite.sticky_header ? 'layout-header--shift' : ''">
			<router-view/>
		</main>
		<layout-footer/>

		<login-registration-modal/>
		<advert-apply-modal/>
		<register-verify-modal/>

		<advert-search-location-modal v-if="$route.name === 'adverts' || $route.name === 'home'"/>

	</template>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import LayoutHeader from "../Components/Layout/LayoutHeader.vue";
import LoginRegistrationModal from "../Components/Modals/LoginRegistration/LoginRegistrationModal.vue";
import LayoutFooter from "../Components/Layout/LayoutFooter.vue";
import {useAuthStore} from "../Stores/Auth/AuthStore";
import {useLanguagesStore} from "../Stores/LanguagesStore";
import {LayoutCssVariables} from "../Utils/LayoutCssVariables";
import AdvertSearchLocationModal from "../Components/Modals/AdvertSearchLocationModal.vue";
import AdvertApplyModal from "../Components/Modals/AdvertApply/AdvertApplyModal.vue";
import {useSiteCheckStore} from "../Stores/SiteCheck/SiteCheck";
import RegisterVerifyModal from "@WebApp/Components/Modals/ProfilePage/RegisterVerifyModal.vue";
import {SiteCheckInterface} from "@WebApp/Stores/SiteCheck/Interfaces/SiteCheckInterface";
import Cookie from "js-cookie";

export default defineComponent({
	name: "App",
	components: {RegisterVerifyModal, AdvertApplyModal, AdvertSearchLocationModal, LayoutFooter, LoginRegistrationModal, LayoutHeader},
	setup() {
		return {
			languagesStore: useLanguagesStore(),
			authStore: useAuthStore(),
			siteStore: useSiteCheckStore(),
		}
	},
	mounted() {
		this.authStore.getMe();
		this.siteStore.getSite();

		if (this.authStore.isLoggedIn) {
			this.authStore.getSDKData();
		}
	},
	computed: {
		bgColor(): string {
			return `var(--${LayoutCssVariables.page.bgColor})`;
		},
		currentSite(): SiteCheckInterface {
			return this.siteStore.currentSite;
		},
		selectedLang(): string {
			return Cookie.get('lang') ?? this.currentSite.default_language;
		}
	},
	watch: {
		currentSite() {
			if (this.currentSite) {
				this.languagesStore.getTranslationList(this.selectedLang);
			}
		}
	}
});
</script>

<style scoped>
main {
	background-color: v-bind(bgColor);
}
</style>
